import { reactive } from 'vue'

const store = reactive({
  assessmentData: null,
  
  setAssessmentData(data) {
    this.assessmentData = data
  },
  
  getAssessmentData() {
    return this.assessmentData
  },

  setNineQData(data) {
    if (!this.assessmentData) {
      this.assessmentData = {}
    }
    this.assessmentData.nineQ = data
  }
})

export default store
