<template>
  <div id="app">
    <Preloader />
    <MobileNavMenu :is-open="isMobileMenuOpen" @close="closeMobileMenu" />
    <SiteHeader @toggle-mobile-menu="toggleMobileMenu" />
    <router-view></router-view>
    <SiteFooter />
  </div>
</template>

<script>
import SiteHeader from './components/Header.vue'
import MobileNavMenu from './components/MobileMenu.vue'
import SiteFooter from './components/SiteFooter.vue'
import Preloader from './components/Preloader.vue'

export default {
  name: 'App',
  components: {
    SiteHeader,
    MobileNavMenu,
    SiteFooter,
    Preloader
  },
  data() {
    return {
      isMobileMenuOpen: false
    }
  },
  methods: {
    closeMobileMenu() {
      this.isMobileMenuOpen = false
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen
    }
  }
}
</script>

<style>
:root {
  --home4-primary: #28bf7e;
}
</style>
