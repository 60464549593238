import { createRouter, createWebHistory } from 'vue-router'
import AssessmentResult from '../views/AssessmentResult.vue'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('../views/HomePage.vue')
    },
    {
      path: '/assessment',
      name: 'Assessment',
      component: () => import('../views/AssessmentPage.vue')
    },
    {
      path: '/assessment/9q',
      name: 'Assessment9Q',
      component: () => import('../views/Assessment9Q.vue')
    },
    {
      path: '/assessment/result',
      name: 'AssessmentResult',
      component: AssessmentResult,
      props: true
    },
    {
      path: '/result/9q',
      name: 'Result9Q',
      component: () => import('../views/Result9Q.vue')
    },
    {
      path: '/result-9q',
      name: 'Result9Q',
      component: () => import('../views/Result9Q.vue')
    },
    {
      path: '/self-care',
      name: 'SelfCare',
      component: () => import('../views/SelfCarePage.vue')
    },
    {
      path: '/chatbot',
      name: 'Chatbot',
      component: () => import('../views/ChatbotPage.vue')
    },
    {
      path: '/contact',
      name: 'Contact',
      component: () => import('../views/ContactPage.vue')
    },
    {
      path: '/booking',
      name: 'BookingForm',
      component: () => import('../views/BookingForm.vue')
    },
    {
      path: '/booking-name',
      name: 'BookingFormWithName',
      component: () => import('../views/BookingFormWithName.vue')
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

export default router
