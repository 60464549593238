<template>
  <header class="header-area">
    <div class="header-menu">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-4">
            <div class="logo">
              <router-link to="/">
                <img src="@/assets/logo.png" alt="Logo">
              </router-link>
            </div>
          </div>
          <div class="col-lg-8 d-flex justify-content-end">
            <div class="main-menu d-none d-lg-block">
              <nav>
                <ul>
                  <li><router-link to="/" exact-active-class="active">หน้าหลัก</router-link></li>
                  <li><router-link to="/assessment" active-class="active">ประเมินสุขภาพใจตัวเอง</router-link></li>
                  <li><router-link to="/self-care" active-class="active">Self-care : วิธีดูแลสุขภาพใจตัวเอง</router-link></li>
                  <li><router-link to="/chatbot" active-class="active">หน่วยงาน&แชทบอทดูแลใจ</router-link></li>
                  <li><router-link to="/booking-name" active-class="active"><i class="fas fa-calendar-check me-1"></i>จองคิวรับการบำบัด</router-link></li>
                  <li><router-link to="/contact" active-class="active">ติดต่อเรา</router-link></li>
                </ul>
              </nav>
            </div>
            <div class="mobile-menu-trigger-wrapper d-lg-none ms-auto">
              <button class="mobile-menu-trigger" @click="$emit('toggle-mobile-menu')">
                <span></span>
                <span></span>
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <br>
  <br>
  <br>  
</template>

<script>
export default {
  name: 'SiteHeader'
}
</script>

<style scoped>
.header-area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  background: #fff;
  box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

.header-menu {
  padding: 15px 0;
}

.row {
  position: relative;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-height: 50px;
}

.main-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.main-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.main-menu li {
  margin: 0 15px;
}

.main-menu a {
  color: #333;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  white-space: nowrap;
}

.main-menu a:hover,
.main-menu a.active {
  color: var(--home4-primary);
}

.mobile-menu-trigger-wrapper {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.mobile-menu-trigger {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  height: 40px;
  width: 40px;
}

.mobile-menu-trigger span {
  display: block;
  width: 25px;
  height: 2px;
  background: #333;
  transition: 0.3s;
}

@media (max-width: 991px) {
  .main-menu {
    display: none;
  }
}
</style>
